export const types = {
  SET_USERNAME: "SET_USERNAME",
  SET_HOVER: "SET_HOVER",
  SET_STATS: "SET_STATS",
  SET_OPEN: "SET_OPEN",
  SET_SELECTION: "SET_SELECTION",
  SET_LEADERS: "SET_LEADERS",
  SET_LOGIN: "SET_LOGIN",
  SET_LOGOUT: "SET_LOGOUT",
};
